import { Portal } from "@reach/portal";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";
import { Paragraph } from "~/components/ui/typography";

interface Props {
	visible: boolean;
	title: string;
	notiKey: string;
	message?: string;
	icon?: React.ReactNode;
	children?: React.ReactNode;
	className?: string;
}

export function Notification({
	visible,
	title,
	message,
	icon,
	children,
	className,
	notiKey,
}: Props) {
	return (
		<AnimatePresence>
			{visible ? (
				<Portal>
					<motion.div
						key={notiKey}
						initial={{ y: "100%", opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: "100%", opacity: 0 }}
						transition={{ ease: "easeInOut", duration: 0.3 }}
						className={clsx(
							"bg-primary fixed bottom-5 left-5 z-50 p-5 shadow-md lg:bottom-7 lg:left-7",
							className
						)}
					>
						<div className="flex gap-3">
							{icon ? icon : null}
							<div>
								<Paragraph>{title}</Paragraph>
								{message ? (
									<Paragraph size="body-small" color="secondary">
										{message}
									</Paragraph>
								) : null}
							</div>
						</div>
						<div className="mt-6">{children}</div>
					</motion.div>
				</Portal>
			) : null}
		</AnimatePresence>
	);
}
